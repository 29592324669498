import './App.css';

import Header from './components/header/header';


function App() {
  return (
    <div className="App">
      <Header />

    </div>
  );
}

export default App;
