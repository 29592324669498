import './contactModule.css';
import { useState } from 'react';
import axios from 'axios';


const ContactModule = () => {

    const [name, setName] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);

    const displayDefault = `Send me a message and I'll respond as soon as I can!`;
    const [display, setDisplay] = useState(displayDefault);

    const handleSubmit = () => {
        
        if (name !== '' && message !== '') {
            
            axios.post('/send', {
                name: name,
                subject: subject,
                message: message
            }).then (response => {
                if (response.status === 201) {
                    setName('');
                    setSubject('');
                    setMessage('');
                    setSuccess(true)
                    setDisplay('Email was sent !');
                }
            })
            .catch (() => {
                setDisplay('Email was not sent, check network connection');
                setSuccess(false);
                console.log('data was not sent to server, check connection');
            })

        } else {
            setDisplay('Please enter the required fields *');
            setSuccess(false);
        }

        
    }


    return (
        <div className = 'contact-wrapper'>
                
                <div className = 'contact-title-wrap'>
                    <h3 className = 'contact-title'>CONTACT</h3>

                    <div className = 'display-wrapper' id = 'resp_display-wrap'>
                        <p className = 'display-text' style = { success ? {color: 'green'} : {color: 'orangered'}}>{ display }</p>
                    </div>
                </div>

                <div className = 'module-wrapper' id = 'resp_module-wrapper'>

                    <div className = 'email-module-wrap' id = 'resp_email-module'>

                        <input type = 'text' className = 'contact-input-field' id = 'name-input'
                                onChange = {(e) => setName(e.target.value)} value = { name }
                                placeholder = '* Name' autoComplete = 'off' />

                        <input type = 'text' className = 'contact-input-field' id = 'subject-input'
                                onChange = {(e) => setSubject(e.target.value)} value = { subject }
                                placeholder = 'Subject' autoComplete = 'off' />

                        <textarea value = {message} className = 'contact-input-field' id = 'msg-input'
                                placeholder = '* Message' value = { message }
                                onChange = {(e) => setMessage(e.target.value)} />

                        <input type = 'button' value = 'Submit'
                                className = 'submit-btn'  onClick = { handleSubmit } />

                    </div>

                    <div className = 'social-module-wrap' id = 'resp_social-module'>

                        <div className = 'phone-wrap'>
                            <div className = 'phone-img' />
                            <p className = 'social-contact-text'>AVAILABLE ON REQUEST 😉</p>
                        </div>

                        <div className = 'linkedin-wrap'>
                            <div className = 'linkedin-img' />
                            <a className = 'linkedin-link' id = 'linkedin_link' href = 'https://www.linkedin.com/in/vishal-prakash-v73'>
                                <p className = 'social-contact-text'>/vishal-prakash-v73</p>
                            </a>
                        </div>

                    </div>


                </div>

                






        </div>
    )
};

export default ContactModule;