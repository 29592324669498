import '../project.css';

const Gateway = () => {
    return (
        <div className = 'project-container'>


            <h1 className = 'project-title' id = 'resp_proj-title'>
                GATEWAY LAWYERS</h1>

            <p className = 'project-brief-text' id = 'resp_brief-text'>
            A demo site recreating a Wix site I’d created for a small business a few years ago - 
            all Wix features are replicated barring the contact module which has obviously not been configured. 
            The ‘Our Profiles’ section loads employees from an embedded JSON file instead of fetching from a server and therefore only uses React for the front-end.
            </p>

                    <p className = 'github-link-text' id = 'resp_github'><i>GITHUB</i>: <a 
                        href = 'https://github.com/vishalrp73/gateway-lawyers.git'
                        className = 'github-link'>
                            https://github.com/vishalrp73/gateway-lawyers.git</a>
                    </p>


            <div className = 'demo-video-wrap' id = 'resp_demo'>
                <iframe src = "https://www.youtube.com/embed/Ag-5w8YVF5c"
                        className = 'video-wrap'
                        title="YouTube video player" frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen='true' />
            </div>


        </div>
    )
};

export default Gateway;