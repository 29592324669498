import '../project.css';

const LevelUp = () => {
    return (
        <div className = 'project-container'>


            <h1 className = 'project-title' id = 'resp_proj-title'>
                LEVELUP WORKS</h1>

            <p className = 'project-brief-text' id = 'resp_brief-text'>
            A project for students to learn to code (or something I'm not really sure) and teachers track their progress.<br />
            Completed under an NZQA Level 4 course in collaboration and recreated after.<br />
            The project loads data from a MySQL database hosted through AWS and uses node.js set up with express to relay data to the the user - the interface being built in React.
            </p>

                    <p className = 'github-link-text' id = 'resp_github'><i>GITHUB (client)</i>: <a 
                        href = 'https://github.com/vishalrp73/LEVELUP_FRONT.git'
                        className = 'github-link'>
                            https://github.com/vishalrp73/LEVELUP_FRONT.git</a>
                    </p>

                    <p className = 'github-link-text' id = 'resp_github'><i>GITHUB (server)</i>: <a 
                        href = 'https://github.com/vishalrp73/LEVELUP_BACK.git'
                        className = 'github-link'>
                            https://github.com/vishalrp73/LEVELUP_BACK.git</a>
                    </p>


            <div className = 'demo-video-wrap' id = 'resp_demo'>
                <iframe src = "https://www.youtube.com/embed/9mYrfj8uLoc"
                        className = 'video-wrap'
                        title="YouTube video player" frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen='true' />
            </div>


        </div>
    )
};

export default LevelUp;