import './bio.css';
import ContactModule from '../../components/contactModule/contactModule';

import cv from '../../docs/VRP_CV_032022.pdf';

const Bio = () => {

    const chevron = `>`;

    const handleClick = (event) => {

        if (event.target.value === 'edu-btn' || event.target.id === 'edu-title') {

            let pan_tog = document.getElementById('edu-panel');
            let font_tog = document.getElementById('edu-title');
            let chevron_tog = document.getElementById('edu-btn-rotate');
            pan_tog.classList.toggle('panel_toggle');
            font_tog.classList.toggle('btn-title-active');
            chevron_tog.classList.toggle('chevron-rotate');

        } else if (event.target.value === 'skills-btn' || event.target.id === 'skills-title') {

            let pan_tog = document.getElementById('skills-panel');
            let font_tog = document.getElementById('skills-title');
            let chevron_tog = document.getElementById('skills-btn-rotate');
            pan_tog.classList.toggle('panel_toggle');
            font_tog.classList.toggle('btn-title-active');
            chevron_tog.classList.toggle('chevron-rotate');

        }

    }


    return (
        <div className = 'bio-core-wrapper' id = 'resp_bio-core-wrapper'>
            
            
            <div className = 'top-portion' id = 'resp_top-portion'>

                <div className = 'prof_photo' id = 'prof_pic' />

                <h4 className = 'bio-intro-text' id = 'resp_bio-intro-text'>
                    <i>I've got a fancy, <b>MOTION HEAVY</b> portfolio on the way but in the meantime, here's all you need!</i><br /><br />
                        24 year old Film and Sociology major of the University of Auckland TURNED DEVELOPER 😮 because writing good scripts is
                        hard and the being broke isn't an ideal lifestyle. If you're wondering how this came to be, I honestly couldn't tell you
                        - one day I started to learn coding and it just never stopped. So here I am! And I actually really enjoy it; sometimes it's
                        a whole lot easier sitting on my fatass coding all day than racking my brain for underdeveloped screenplay ideas.
                </h4>

                <a download href = { cv } className = 'pdf-dl-wrapper' id = 'resp_pdf' >
                    <p className = 'cv-text' ><p className = 'chevron-rotate' >{ chevron }</p>click to download my CV! [pdf]</p>
                </a>


            </div>

            <div className = 'bottom-portion' id = 'resp_bottom'>

               <div className = 'accordion-wrapper' id = 'edu_accordion' >

                   <div className = 'btn-wrap' >
                        <button className = 'accordion-btn' value = 'edu-btn'
                            onClick = { (e) => handleClick(e) } id = 'edu-btn-rotate'>{chevron}</button>
                        <p className = 'btn-title' id = 'edu-title' onClick = {(e) => handleClick(e)}>EDUCATION</p>
                   </div>

                    <div className = 'accordion-panel' id = 'edu-panel'>
                        <div className = 'panel-wrap'>
                            
                            <ul >
                                <p className = 'panel-list-title'>2021</p>
                                <li className = 'panel-list-item'>NZQA Level 4: Certificate in Technology Product Development Essentials</li>
                            </ul>
                            
                            <ul>
                                <p className = 'panel-list-title'>2020</p>
                                <li className = 'panel-list-item'>University of Auckland - B.A Double Major:
                                    <ul>
                                        <li className = 'panel-sub-item'>Film, Media and Television &amp; Sociology</li>
                                    </ul>
                                </li>
                            </ul>

                            <ul>
                                <p className = 'panel-list-title'>2019</p>
                                <li className = 'panel-list-item'>Professional Scrum Master I (scrum.org)</li>
                            </ul>

                            <ul>
                                <p className = 'panel-list-title'>2015</p>
                                <li className = 'panel-list-item'>Botany Downs Secondary College (NCEA Level 3)</li>
                                    <ul>
                                        <li className = 'panel-sub-item'>Excellence endorsements in English, Media Studies and Digital Technologies with Programming</li>
                                    </ul>
                            </ul>

                            <p className = 'extra-title'>EXTRA</p>

                            <ul>
                                <li className = 'panel-list-item'>Shortlisted for The Outlook for Someday Film Festival - <i>'Act On It'</i></li>
                            </ul>

                        </div>
                    </div>

               </div>

               <div className = 'accordion-wrapper' id = 'skills_accordion' >

                   <div className = 'btn-wrap' >
                        <button className = 'accordion-btn' value = 'skills-btn'
                            onClick = { (e) => handleClick(e) } id = 'skills-btn-rotate'>{chevron}</button>
                        <p className = 'btn-title' id = 'skills-title' onClick = {(e) => handleClick(e)}>
                                SKILLS</p>
                   </div>

                    <div className = 'accordion-panel' id = 'skills-panel'>
                        
                        <div className = 'panel-wrap'>

                            <ul>
                                <li className = 'skills-item'>React</li>
                                <li className = 'skills-item'>MongoDB + Atlas</li>
                                <li className = 'skills-item'>Node.js</li>
                                <li className = 'skills-item'>Express.js</li>
                                <li className = 'skills-item'>MySQL</li>
                                <li className = 'skills-item'>Jest</li>
                                <li className = 'skills-item'>CI/CD: Github Actions</li>
                                <li className = 'skills-item'>SonarCloud</li>
                                <li className = 'skills-item'>Figma, XD, Invision Studio</li>
                                <li className = 'skills-item'>Git</li>
                                <li className = 'skills-item'>Docker</li><br />
                                <li className = 'skills-item'>AWS</li>
                                    <ul>
                                        <li className = 'sub-item'> S3 Buckets</li>
                                        <li className = 'sub-item'> Cloud9</li>
                                        <li className = 'sub-item'> RDS</li>
                                        <li className = 'sub-item'> Elastic Containers</li>
                                        <li className = 'sub-item'> Rekognition</li>
                                    </ul><br />
                                <li className = 'skills-item'>IBM Cloud</li>
                                    <ul>
                                        <li className = 'sub-item'> Watson Discovery</li>
                                        <li className = 'sub-item'> Watson Assistant</li>
                                    </ul><br />
                                <li className = 'skills-item'>DevOps / Agile methodology</li><br />
                                <li className = 'skills-item'>Adobe Creative Cloud (proficiences):
                                    <ul>
                                        <li className = 'sub-item'> Premiere Pro</li>
                                        <li className = 'sub-item'> After Effects</li>
                                        <li className = 'sub-item'> Illustrator</li>
                                        <li className = 'sub-item'> Photoshop</li>
                                        <li className = 'sub-item'> XD</li>
                                        <li className = 'sub-item'> InDesign</li>
                                        <li className = 'sub-item'> Lightroom</li>
                                        <li className = 'sub-item'> Audition</li>
                                    </ul>
                                </li>
                                <li className = 'skills-item'>Git</li>
                                <li className = 'skills-item'>Final Draft 11</li>
                                <li className = 'skills-item'>Logic Pro</li>
                            </ul>




                            
                        </div>

                    </div>

               </div>


            </div>

            <ContactModule />


        </div>
    )
};

export default Bio;