import './turners.css';

const Turners = () => {
    return (
        <div className = 'project-container'>

            <h1 className = 'project-title' id = 'resp_proj-title'>TURNERS CAR INSURANCE</h1>

            <p className = 'project-brief-text' id = 'resp_proj-title'>
                Designed by a lovely and extremely talented UX designer at Mission Ready HQ,
                the Turners Car Insurance prototype demonstrates a new method of purchasing
                vehicle insurance online. The user can run through the quote process, searching
                their number plate and specific addresses, as well as having their quote emailed
                to them. The development deviates from the design in some areas, namely in form
                validation and extra buttons to secure user data - however attempts to remain true
                to the initial design.<br /><br />
                This was developed with React, Node, Express and MongoDB, where upon completion of
                the form, the details are uploaded to an Atlas cluster database. There is also simple
                authentication conducted through Bcrypt where a user with correct details can sign in
                to view a protected page.
            </p>

            <p className = 'live-url-text' id = 'resp_url'><i>URL</i>: <a
                href = 'https://sleepy-tor-12357.herokuapp.com/'
                className = 'live-url-link'>
                    https://sleepy-tor-12357.herokuapp.com/
                </a>
            </p>

            <p className = 'github-link-text' id = 'resp_github'><i>GITHUB</i>: <a
                href = 'https://git.heroku.com/sleepy-tor-12357.git'
                className = 'github-link'>https://git.heroku.com/sleepy-tor-12357.git</a>
            </p>

            <div className = 'demo-video-wrap' id = 'resp_demo'>
                <iframe src = 'https://www.youtube.com/embed/bxs1GETCrvI'
                    className = 'video-wrap'
                    title = 'YouTube video player' frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen='true' />
            </div>

        </div>
    )
}

export default Turners;