import '../project.css';
import './ux.css';
import ReactPlayer from 'react-player';

import lancer_logo from '../../../docs/LancerLogoAnimation.gif';
import design_lang from '../../../docs/DesignLanguage.png';

import case_study from '../../../docs/VRP_LANCER-FN_GB.pdf';

const UX = () => {

    const caret = '>';


    return (
        <div className = 'project-container'>


            <h1 className = 'project-title' id = 'resp_proj-title'>
                UX / UI (2020)</h1>

            <div className = 'ux-project-brief-wrap'>
                <p className = 'ux-project-brief-text' id = 'resp_brief-text'>
                        In a confusing haze that was the end of 2020, I completed a brief case study in UX, which I had been independently learning. 
                        While I look back on this with reservations about its overall quality, I still believe the full study showcases some foundational skills in UX - 
                        from research to testing, as well as demonstrating an ability to markup wireframes and high fidelity designs for interfaces. 
                        I feel this has provided me with some insight into the world of user consideration - at the very least.<br /><br />
                        <i><span style = {{fontSize: '20px', fontWeight: 'bold'}}>DECEMBER 28TH 2020</span><br />(in the midst of a Cyberpunk 2077 playthrough on my terminally-ill PS4)</i><br />
                    </p>
                <a download href = { case_study } style = {{textDecoration: 'none', color: 'red'}}>
                    <div className = 'dl-pdf'>
                    <p className = 'dl-text'>{ caret }</p>
                    </div>
                </a>
                <p className = 'dl-instr-text'>click to download full PDF case study (30pg, 2.2mb)</p>
            </div>

            <div className = 'lancer-logo-wrap' id = 'resp_logo-wrap'>
                <img src = { lancer_logo } className = 'lancer-logo-img' id = 'resp_logo-img' />
            </div>

            { /* SECTIONS WITHOUT COMPONENTS */ }

            <section className = 'section-container' id = 'und_users-cont'>

                <div className = 'section-content-wrapper'>
                    <h2 className = 'section-title' id = 'resp_section-title'>UNDERSTANDING USERS</h2>
                    <p className = 'section-sub-title' id = 'resp_sub-title'>
                        <i>Full graphics and ideations featured in full case study PDF</i></p>

                    <p className = 'section-text' id = 'resp_section-text'>
                    I compiled analyses of the three applications, primarily focusing on their mobile versions as this market is the intended entry point. I picked these three because they seemed to contain parts of features I had initially conceptualized and I was determined to find commonalities and turning points that could influence my own designs.<br /><br />
                    Additionally, I conducted discussions with various acquaintances within my target demographic (literally anyone in the creative arts) discussing their experiences in the workforce, highlighting relevant issues within the hiring process as well as conveying interactions with some of the researched applications. It’s through this process that I found a common theme of contractors feeling somewhat alienated by the industry workforce in that broken or inefficient communication could potentially lead to workers being exploited for extra labour where there was not an adequate medium to negotiate compensation. In the other sense, recruiters sometimes had difficulties approximating the exact talents of individuals and noted a uniform platform would alleviate difficulties, particularly when candidates become difficult to contact.<br /><br />
                    My interactions with interviewees allowed me to create mental models, sitemaps and extended wireframes to better conceptualise and understand my own idea of what the app should look like.
                    </p>

                    <div className = 'section-media-wrap' id = 'resp_media-wrap-und_users'>

                        <ReactPlayer
                            url = 'https://folio-media.s3.ap-southeast-2.amazonaws.com/lfdd/SiteMap.mp4'
                            width = '100%' height = 'fit-content' controls = 'true' muted = 'true'
                            playing = 'true' loop = 'true'
                            style = {{margin: '10px 25px', backgroundColor: '#212121', borderRadius: '8px', border: '5px solid #212121'}} />

                        <ReactPlayer
                            url = 'https://folio-media.s3.ap-southeast-2.amazonaws.com/lfdd/SiteMap2.mp4'
                            width = '100%' height = 'fit-content' controls = 'true' muted = 'true'
                            style = {{margin: '10px 25px', backgroundColor: '#212121', borderRadius: '8px', border: '5px solid #212121'}}
                            playing = 'true' loop = 'true' />

                        <ReactPlayer
                            url = 'https://folio-media.s3.ap-southeast-2.amazonaws.com/lfdd/LFDD.mp4'
                            width = '100%' height = 'fit-content' controls = 'true' muted = 'true'
                            style = {{margin: '10px 25px', backgroundColor: '#212121', borderRadius: '8px', border: '5px solid #212121'}}
                            playing = 'true' loop = 'true' />


                    </div>

                </div>

            </section>

            <section className = 'section-container' id = 'ideate-cont'>

                <div className = 'section-content-wrapper'>
                    <h2 className = 'section-title' id = 'resp_section-title'>IDEATION &amp; TESTING</h2>
                    <p className = 'section-sub-title' id = 'resp_sub-title'>
                        <i>Full graphics and ideations featured in full case study PDF</i></p>

                    <p className = 'section-text' id = 'resp_section-text'>
                    I proceeded to create a prototype to test and evaluate the logistical and practical functions of the application. Construction involved frequent feedback and reiterations from multiple participants.
                    </p>

                    <div className = 'section-media-wrap' id = 'resp_media-wrap-ideate'>

                        <ReactPlayer
                            url = 'https://folio-media.s3.ap-southeast-2.amazonaws.com/ideation_testing/Search.mp4'
                            width = '100%' height = 'fit-content' controls = 'true' muted = 'true'
                            playing = 'true' loop = 'true'
                            style = {{margin: '10px 25px', backgroundColor: '#212121', borderRadius: '8px', border: '5px solid #212121'}} />

                        <ReactPlayer
                            url = 'https://folio-media.s3.ap-southeast-2.amazonaws.com/ideation_testing/Me.mp4'
                            width = '100%' height = 'fit-content' controls = 'true' muted = 'true'
                            style = {{margin: '10px 25px', backgroundColor: '#212121', borderRadius: '8px', border: '5px solid #212121'}}
                            playing = 'true' loop = 'true' />

                        <ReactPlayer
                            url = 'https://folio-media.s3.ap-southeast-2.amazonaws.com/ideation_testing/Cont_People.mp4'
                            width = '100%' height = 'fit-content' controls = 'true' muted = 'true'
                            style = {{margin: '10px 25px', backgroundColor: '#212121', borderRadius: '8px', border: '5px solid #212121'}}
                            playing = 'true' loop = 'true' />


                    </div>

                </div>

            </section>

            <section className = 'section-container' id = 'fn-proto-cont'>
                
                <h2 className = 'section-title' id = 'resp_section-title'>FINAL PROTOTYPE</h2>
                <div className = 'section-content-wrapper-fn-proto' id = 'fn-proto-wrap'>
                    

                    <div className = 'fn-left-portion' id = 'resp_fn-proto-left'>
                        <img src = { design_lang } className = 'design_lang-img' />
                    </div>

                    <div className = 'fn-right-portion' id = 'resp_fn-right-portion'>
                        <h4 id = 'onboarding-title'>ONBOARDING</h4>
                        <p className = 'onboard-text' id = 'resp_onboarding-text'>
                            The user begins by selecting the type of account they wish to have – differences between company and individuals pertain to abilities to list and manage applicants. User information is then obtained in compartments to simplify the user’s mental image of the data they are consenting to publicly publish. A summary of biographical information is finally shown and the user can proceed to edit their portfolio in the editor.
                        </p>

                        <ReactPlayer
                            url = 'https://folio-media.s3.ap-southeast-2.amazonaws.com/final_prototype/REG-Tab_EDITED.mp4'
                            width = '50%' height = 'fit-content' controls = 'true' muted = 'true'
                            style = {{backgroundColor: '#212121', borderRadius: '8px', border: '5px solid #212121'}}
                            playing = 'true' loop = 'true' />
                    </div>


                </div>

            </section>


            <section className = 'fn-proto-section' id = 'me-container'>

                <div className = 'fn-proto-wrapper' id = 'resp_fn-proto-wrapper'>

                    <div className = 'fn-proto-left-bar' id = 'resp_fn-proto-left'>
                        <h4 className = 'fn-proto-title' id = 'resp_fn-proto-title'><i>'ME'</i></h4>
                        <p className = 'fn-proto-text' id = 'resp_fn-proto-text'>
                            The user’s personal functions are situated in the first tab and is easily identified with a dark theme. This simulates a ‘cinematic’ or ‘showcase’ aesthetic to allow individual work and details to pop to the forefront. Throughout the app, whenever an employer clicks on an individual, they are shown this profile of the person – each user may customise the basic visual design of the section with text/layout functions, however, the core infrastructure of each user’s cards are identical to one another so as to maintain ‘equal opportunity’ among Lancers; the core attraction is designed to be towards the work individuals showcase.<br /><br />
                            Users may upload any supported common media – images, animations, videos, audio, PDF documents and external links. Pages are separated into ‘Profile’ (personal biography and other relevant information) and ‘Works’ – which also may have sub-pages to showcase different pieces if the user desires.<br /><br />
                            As the hiring process often involves high financial risk, users are required to be verified through genuine documents and addresses. The intention of this application is to skip or expedite the interview process to get right to work as soon as possible. This REQUIRES every user to be exactly who they say they are – this isn’t a dating app.
                        </p>
                    </div>

                    <div className = 'fn-proto-right-bar' id  = 'resp_fn-proto-right-bar'>
                        <ReactPlayer
                            url = 'https://folio-media.s3.ap-southeast-2.amazonaws.com/final_prototype/LANCER-ME_TAB.mp4'
                            width = '400px' height = 'fit-content' controls = 'true' muted = 'true'
                            style = {{backgroundColor: '#212121', borderRadius: '8px', border: '5px solid #212121'}}
                            playing = 'true' loop = 'true' />
                    </div>
                
                </div>

            </section>

            <section className = 'fn-proto-section' id = 'search-container'>

                <div className = 'fn-proto-wrapper' id = 'resp_fn-proto-wrapper'>

                    <div className = 'fn-proto-left-bar' id = 'resp_fn-proto-left'>
                        <h4 className = 'fn-proto-title' id = 'resp_fn-proto-title'><i>'SEARCH'</i></h4>
                        <p className = 'fn-proto-text' id = 'resp_fn-proto-text'>
                            The sequencing of the tabs are meant to guide the user into the holistic experience – where one starts by introducing themselves to the world (industry) in ‘Me’, then proceeds to explore their local variety in the ‘Search’ tab. Light is instead used in this tab in the theme of ‘exploration’, with a dark green header conveying this ideology and a naturally lighter dock flanking both ends of the canvas. They frame the core content of the map, allowing the user to centrally focus on exploring their local from the skies like a bird. Orange pins locate companies operating within the industry and blue pins locate companies with active listings.<br /><br />
                            Search functionality is provided with filters to allow users to whittle out potential opportunities. The submenu categorises the core three types in Listings, Companies and Lancers. Users are able to peruse these categories and view all relevant information. Content is separated into cards that also have quick swipe actions of the most important features, allowing quick saving and contacting.<br /><br />
                            Cards also show quick relevant information in location, salary and term - and are distinguished by notable icons to quickly convey the layout of this convention. Once this convention is known, users can easily scan for pertinent information, increasing the efficiency of exploration. 
                        </p>
                    </div>

                    <div className = 'fn-proto-right-bar' id  = 'resp_fn-proto-right-bar'>
                        <ReactPlayer
                            url = 'https://folio-media.s3.ap-southeast-2.amazonaws.com/final_prototype/LANCER-SEARCH_TAB.mp4'
                            width = '400px' height = 'fit-content' controls = 'true' muted = 'true'
                            style = {{backgroundColor: '#212121', borderRadius: '8px', border: '5px solid #212121'}}
                            playing = 'true' loop = 'true' />
                    </div>

                </div>

            </section>

            <section className = 'fn-proto-section' id = 'contracts-container'>

                <div className = 'fn-proto-contracts-wrapper' id = 'resp_fn-proto-wrapper'>

                    <div className = 'fn-proto-left-bar-contracts' id = 'resp_fn-proto-left-bar-contracts'>
                        <h4 className = 'fn-proto-title' id = 'resp_fn-proto-title'><i>'CONTRACTS'</i></h4>
                        <p className = 'fn-proto-text' id = 'resp_fn-proto-text-contracts'>
                            Next in the sequence is ‘Contracts’ – where individuals or companies may view open, current, saved or past listings. A listing is considered ‘open’ when employers commission the work to be carried out. ‘Current’ listings are those that employers are actively searching individuals for whereas ‘past’ listings are simply each account’s history of executed listings.<br /><br />
                            Within the ‘open’ listings, both employer and employee can submit work through attached files, request or push payments for completed jobs, or message each other (the button directly links to the open message thread. Employees (Lancers) can generate tax invoices per jurisdiction setup (in settings, ‘Me’ tab) and Employers can push payments directly (I suppose the capitalist in me says you could add a surcharge/fee for transactions within the app and call it a “middleman fee”) to Lancers.<br /><br />
                            Available for ‘Company’ accounts only, active listings are viewable with the ability to sort candidates into three categories – unsorted, potential and unsuitable. An individual placed in any of these categories can be easily moved between and they will not be notified of their position until the contract has opened. To commission work, employers can message Lancer(s) to detail their wants and needs and formally open the contract.<br /><br />
                            Another feature is the ‘Like’ which is intentionally simplistic so as not to complicate the hiring process and add discrimination between Lancers. The purpose of this feature is to allow both individual Lancers and companies to quickly gauge each other – assurance for financial or labour risk. Once a contract has been completed, both parties are optionally given a ‘Like’ button which pops up a panel showing the individual(s) or company involved in the contract and a larger glowing ‘thumbs up’ button. Once this is clicked, a four second timer starts, and the user can again rapidly tap on the button to send the other party as many likes as they want. As the number of likes is directly tied to how fast the other user can/decides to tap, the likes are non-sensical, but provide a positive social experience for both parties and can strengthen the bond between Lancer and Company. This also somewhat levels the field between Lancers when Companies try to evaluate their likes – an individual with many likes is well regarded, but that does not mean individuals with less likes are less regarded.<br /><br />
                            Quick swipe options are also available with important actions and the header colour is instead a dark blue, conveying comfort and conventionalising it as the ‘Contracts’ tab.<br />
                        </p>
                    </div>

                    <div className = 'fn-proto-right-bar-contracts' id  = 'resp_fn-proto-right-bar-contracts'>
                        <ReactPlayer
                            url = 'https://folio-media.s3.ap-southeast-2.amazonaws.com/final_prototype/LANCER-CONTRACTS_TAB.mp4'
                            width = '400px' height = 'fit-content' controls = 'true' muted = 'true'
                            style = {{backgroundColor: '#212121', borderRadius: '8px', border: '5px solid #212121'}}
                            playing = 'true' loop = 'true' />
                        <ReactPlayer
                            url = 'https://folio-media.s3.ap-southeast-2.amazonaws.com/final_prototype/EMP_CONT_EDITED-mp4.mp4'
                            width = '400px' height = 'fit-content' controls = 'true' muted = 'true'
                            style = {{backgroundColor: '#212121', borderRadius: '8px', margin: '0px 20px', border: '5px solid #212121'}}
                            playing = 'true' loop = 'true' />
                    </div>
                
                </div>

            </section>

            <section className = 'fn-proto-section' id = 'people-container'>

                <div className = 'fn-proto-wrapper' id = 'resp_fn-proto-wrapper'>

                    <div className = 'fn-proto-left-bar' id = 'resp_fn-proto-left'>
                        <h4 className = 'fn-proto-title' id = 'resp_fn-proto-title'><i>'PEOPLE'</i></h4>
                        <p className = 'fn-proto-text' id = 'resp_fn-proto-text'>
                            The final tab in the process is the ‘People’ Tab. This centralises all communications and scheduling. Separated by two main pages – Messages and Contacts, it also contains a drop-down calendar to communicate appointments and meetings. This calendar is purely for scheduling PEOPLE and not deadlines, and appointments can send reminders through the relevant message thread if needed. Group message threads are displayed with a folder icon and threads have sending functionality. Any supported file (including application-specific like .ai  or .js, etc) is available to send to aid collaboration between Lancers.<br /><br />
                            Unsolicited messages are stored in requests or can be turned off, and threads can be sorted and pinned. Threads contain timestamps and keyword searching to aid efficiency for referencing. The Contacts page lists all active and saved contacts. Clicking on an individual’s page takes you to their biography card.
                        </p>
                    </div>

                    <div className = 'fn-proto-right-bar' id  = 'resp_fn-proto-right-bar'>
                        <ReactPlayer
                            url = 'https://folio-media.s3.ap-southeast-2.amazonaws.com/final_prototype/LANCER-PEOPLE_TAB.mp4'
                            width = '400px' height = 'fit-content' controls = 'true' muted = 'true'
                            style = {{backgroundColor: '#212121', borderRadius: '8px', border: '5px solid #212121'}}
                            playing = 'true' loop = 'true' />
                    </div>
                
                </div>

            </section>

            



    </div>
    )
}

export default UX;