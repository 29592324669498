import './header.css';
import { BrowserRouter as Router, Switch, Route, NavLink } from 'react-router-dom';
import Bio from '../../pages/bio/bio';
import Works from '../../pages/works/works'

const Header = () => {
    return (
    <div className = 'core-wrapper'>

        <Router >

        <div className = 'header-wrapper' id = 'resp_header-wrapper'>


                <div className = 'name-wrapper' id = 'resp_name-wrapper'>
                    <h1 className = 'name-text' id = 'resp_name-text'
                            >V I S H A L &nbsp; P R A K A S H</h1>
                </div>

                <div className = 'menu-wrapper' id = 'resp_menu-wrapper'>

                    <NavLink exact to = '/' id = 'works-link'
                            className = 'nav-link' activeClassName = 'works-link-active' >
                            <h2 className = 'nav-text' id = 'resp_nav-text' >WORKS</h2>
                    </NavLink>

                    <NavLink to = '/bio.js' id = 'bio-link'
                            className = 'nav-link' activeClassName = 'bio-link-active' >
                            <h2 className = 'nav-text' id = 'resp_nav-text' >BIO</h2>
                    </NavLink>


                </div>

        </div>

        <div className = 'route-wrapper' >
                        <Switch>

                            <Route exact path = '/'>
                                <Works />
                            </Route>

                            <Route exact path = '/bio.js'>
                                <Bio />
                            </Route>

                        </Switch>
        </div>

        </Router >

    </div>
    )
};

export default Header;