import '../project.css';
import './filmBlog.css';

const FilmBlog = () => {





    return (
        <div className = 'project-container'>


            <h1 className = 'project-title' id = 'resp_proj-title'>
                FILM BLOG : CRITERION <i>(for idiots)</i></h1>

            <p className = 'project-brief-text' id = 'resp_brief-text'>
            Dude I don’t know, there’s a whole lot of time in a Level 4 lockdown.<br />
            I wanted a ‘flagship’ site for my portfolio and decided to make some use of my Film major. There are (at current) 50 films I chose to showcase a simple site that displays these films with relevant details, a trailer and an <i>unmoderated</i> comment section.<br /><br />
            The user can also sort films by decade, letter and receive a random film selection. A search function is also available that uses fuzzy searching through fuse.js, as well as a rotating set of background graphics that randomly select on load.<br /><br />
            I used this project to learn MongoDB - which hosts the full set of films and comments. This data loads to an express.js / node.js server which is then passed onto the client UI - built in React.<br /><br />
            I deployed the application through Heroku, however I keep a testing-replica updated on Github.<br />
            I'd note that only 23/50 (currently) films have reviews, however the rest are still accessible.<br /><br />
            If you enjoy discussing bourgeois and overrated cinema, take a look!
            The reviewed films are:
                <ul className = 'reviewed-list'>
                    <li>Apocalypse Now (1979)</li>
                    <li>A Scanner Darkly (2006)</li>
                    <li>Brazil (1985)</li>
                    <li>Children of Men (2006)</li>
                    <li>Come and See (1985)</li>
                    <li>Das Boot (1981)</li>
                    <li>Ghost in the Shell (1995)</li>
                    <li>Mad Max 2 (1981)</li>
                    <li>Perfect Blue (1997)</li>
                    <li>Persona (1966)</li>
                    <li>Punch-Drunk Love (2002)</li>
                    <li>Parasite (2019)</li>
                    <li>Ran (1985)</li>
                    <li>Rope (1948)</li>
                    <li>Solaris (1972)</li>
                    <li>Seven Samurai (1954)</li>
                    <li>Three Colors: Red (1994)</li>
                    <li>Thief (1981)</li>
                    <li>The Intern (2015)</li>
                    <li>True Romance (1993)</li><br />
                    <li>-----------------------</li>
                    <li><i>* UPDATED *</i></li>
                    <li>-----------------------</li>
                    <li>Platoon (1986)</li>
                    <li>American Beauty (1999)</li>
                    <li>L.A. Confidential (1997)</li>
                </ul>
            </p>

            <p className = 'live-url-text' id = 'resp_url'><i>URL</i>: <a 
                href = 'https://stark-reaches-11078.herokuapp.com'
                className = 'live-url-link'>
                https://stark-reaches-11078.herokuapp.com</a>
            </p>

            <p className = 'github-link-text' id = 'resp_github'><i>GITHUB</i>: <a 
                href = 'https://github.com/vishalrp73/film_blog.git'
                className = 'github-link'>
                https://github.com/vishalrp73/film_blog.git</a>
            </p>


            <div className = 'demo-video-wrap' id = 'resp_demo'>
                <iframe src = "https://www.youtube.com/embed/QM7jljo49q0"
                        className = 'video-wrap'
                        title="YouTube video player" frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen='true' />
            </div>


        </div>
    )
};

export default FilmBlog;