import './works.css';

import { BrowserRouter as Router, Switch, Route, NavLink } from 'react-router-dom';
import FilmBlog from '../../components/works_routes/blog/filmBlog';
import Turners from '../../components/works_routes/turners/turners';
import LevelUp from '../../components/works_routes/levelup/levelup';
import Gateway from '../../components/works_routes/gateway/gateway';
import UX from '../../components/works_routes/ux/ux';

const Works = () => {



    return (
        <div className = 'works-wrapper' id = 'resp_works-wrapper'>

            <Router >

                <div className = 'proj-menu-wrap' id = 'resp_proj-menu-wrap'>

                    <NavLink exact to = '/' id = 'blog-nav-link'
                            className = 'proj-nav-link' activeClassName = 'blog-link-active'>
                                <h5 className = 'proj-link-text'>FILM BLOG</h5>
                    </NavLink>

                    <NavLink to = '/turners.js' id = 'turners-nav-link'
                            className = 'proj-nav-link' activeClassName = 'turners-link-active'>
                                <h5 className = 'proj-link-text'>TURNERS INSURANCE</h5>
                    </NavLink>

                    <NavLink to = '/levelup.js' id = 'level-nav-link'
                            className = 'proj-nav-link' activeClassName = 'levelup-link-active'>
                                <h5 className = 'proj-link-text'>LEVEL UP WORKS</h5>
                    </NavLink>

                    <NavLink to = '/gateway.js' id = 'gate-nav-link'
                            className = 'proj-nav-link' activeClassName = 'gate-link-active'>
                                <h5 className = 'proj-link-text'>GATEWAY LAWYERS</h5>
                    </NavLink>

                    <NavLink to = '/ux.js' id = 'ux-nav-link'
                            className = 'proj-nav-link' activeClassName = 'ux-link-active'>
                                <h5 className = 'proj-link-text'>UX / UI (2020)</h5>
                    </NavLink>

                </div>

                <div className = 'project-content-wrapper' >

                    <Switch>

                        <Route exact path = '/'>
                            <FilmBlog />
                        </Route>

                        <Route exact path = '/works.js'>
                            <FilmBlog />
                        </Route>

                        <Route exact path = '/turners.js'>
                            <Turners />
                        </Route>

                        <Route exact path = '/levelup.js'>
                            <LevelUp />
                        </Route>

                        <Route exact path = '/gateway.js'>
                            <Gateway />
                        </Route>

                        <Route exact path = '/ux.js'>
                            <UX />
                        </Route>



                    </Switch>



                </div>


            </Router >

        </div>
    )
};

export default Works;